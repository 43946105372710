:root {
  --default-font-size: 16px;
  --header-font-size: min(40px, 8vw);

  --header-logo-size: min(60px, 12vw);

  --block-size: 36px;
  --default-padding-size: 12px;
  --burger-menu-padding-size: 2.5em;

  --maize-crayola: #e9c339ff;
  --indigo-dye: #1f4059ff;
  --cadet-blue-crayola: #a1aebeff;
  --beau-blue: #c7d7e2ff;
  --azure-x-11-web-color: #e5f4fbff;
  --green-color: #138d75;
  --red-color: #d35400;
  --primary-color: var(--indigo-dye);
  --secondary-color: var(--beau-blue);
  --background-color: var(--cadet-blue-crayola);
  --accent-color: var(--maize-crayola);

  --default-background-color: var(--background-color);
  --header-background-color: var(--primary-color);
  --secondary-header-color: var(--primary-color);
  --footer-background-color: var(--header-background-color);
  --burger-menu-morph-color: #373a47;

  --active-button-color: var(--accent-color);
  --hovered-button-color: var(--accent-color);
  --burger-menu-close-button-color: #bdc3c7;

  --default-text-color: black;
  --header-text-color: var(--secondary-color);
  --footer-text-color: var(--header-text-color);
  --burger-menu-text-color: #b8b7ad;

  --page-min-width: calc(var(--block-size) * 27);

  --border-thickness: 2px;
  --border-radius: 6px;
  --border-color: black;
  --secondary-border-color: gray;
}

body {
  line-height: 1.5em;
  /* text-shadow: 4px 4px 5px gold; */
}

mark {
  padding: 0;
}

h3 {
  margin-block-end: auto;
}

a {
  color: var(--primary-color);
}

table {
  height: auto;
}

.Main-Toolbar a {
  color: var(--secondary-color);
}

.Main-Footer a {
  color: var(--accent-color);
}

@media (max-width: 970px)
{
  .Stats-Pane-Row-Container {
    align-self: start!important;
  }
  .Main-Toolbar-Bottom-Section {
    display: none!important;
  }
  .Main-Toolbar-Burger-Menu {
    display: flex!important;
  }
}

@media (max-width: 510px)
{
  .Main-Toolbar-Bottom-Section {
    display: none!important;
  }
  .Main-Toolbar-Burger-Menu {
    display: flex!important;
  }
}

.App {
  text-align: center;
  background-color: var(--default-background-color);
  min-height: 100vh;
  font-family: 'Jost', Calibri, 'Trebuchet MS', sans-serif;
  font-size: var(--default-font-size);
  color: var(--default-text-color);
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  height: 1px;
}

.Main-Toolbar {
  display: inline-block;
  /*min-width: fit-content;*/
  width: 100%;
  /*height: calc(var(--block-size) * 4);*/
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  z-index: 1024!important;
}

.Main-Toolbar-Top-Section {
  /*height: calc(var(--block-size) * 3);*/
  /*min-width: var(--page-min-width);*/
  line-height: 1em;
  width: 100%;
  display: flex;
  padding-bottom: calc(var(--default-padding-size)/2);
  border-bottom: var(--border-thickness) solid var(--accent-color);
}

.Main-Toolbar-Top-Section > :not(:last-child) {
  padding-right: var(--default-padding-size);
}

.Main-Toolbar-Link {
  max-width: calc(var(--block-size)*12);
}

.Main-Toolbar-Banner-Image {
  height: 100%;
  max-height: var(--header-logo-size);
  aspect-ratio: 1;
}

.Main-Toolbar-Banner-Text {
  text-align: center;
  /*height:100%;*/
  margin: 0 0.25em;
  font-size: var(--header-font-size);
  display: inline-block;
  vertical-align: middle;
}

a .Main-Toolbar-Banner-Text {
  color: var(--header-text-color);
  text-decoration: none;
}

.Main-Toolbar-Login-Section {
  height: 100%;
  margin: auto;
  flex: 1 1 auto;
}

.Main-Toolbar-Logged-Out-Section,
.Main-Toolbar-Logged-In-Section {
  width: fit-content;
  margin-left: auto;
  padding-right: var(--default-padding-size);
  margin-top: 2px;
}

.Main-Toolbar-Login-Welcome {
  width: fit-content;
  margin-left: auto;
  padding-right: var(--default-padding-size);
}

.Main-Toolbar-Login-Button
{
  border: 2px solid var(--accent-color);
  border-radius: calc(var(--border-radius)*2);
  padding: 6px;
  background-color: var(--primary-color);
  font-size: 1.5em;
}

.Main-Toolbar-Login-Button > a {
  text-decoration: none;
  color: var(--secondary-color);
}

.Main-Toolbar-Login-Button > a:hover {
  color: var(--accent-color);
}

.Main-Toolbar-Logout-Button {
  border: 2px solid black;
  border-radius: var(--border-radius);
  background-color: var(--accent-color);
}

.Main-Toolbar-Bottom-Section {
  /*height: var(--block-size);*/
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  display: flex;
  justify-content: space-evenly;
  background-color: var(--secondary-header-color);
  /*margin-inline-start: 0;*/
  /*margin-inline-end: 0;*/
  /*margin-block-start: 0;*/
  margin-block-end: 0;
  padding-inline-start: var(--default-padding-size);
  list-style-type: none;
}

ul.Main-Toolbar-Bottom-Section > li:first-child {
}

.Main-Toolbar-Bottom-Section-Button {
  display: flex;
  border-radius: var(--border-radius);
  height: var(--block-size);
  margin: 3px 0;
  cursor: pointer;
  border: 2px solid transparent;
}

.Main-Toolbar-Bottom-Section-Button:hover {
  border: 2px solid var(--hovered-button-color);
}

li.Main-Toolbar-Bottom-Section-Button > a {
  color: var(--header-text-color);
  font-size: 120%;
  text-decoration: underline;
  padding: 4px var(--default-padding-size);
  flex: 1 1 auto;
}

.Main-Toolbar-Bottom-Section-Active-Button {
  border: 2px solid var(--hovered-button-color);
}

.Main-Toolbar-Burger-Menu {
  display: none;
}

.Main-Content-Pane {
  display: flex;
  flex: 1;
  padding: var(--default-padding-size);
  overflow: auto;
}

.Main-Footer {
  height: var(--block-size);
  background-color: var(--footer-background-color);
  color: var(--footer-text-color);
  /*min-width: var(--page-min-width);*/
  width: 100%;
}

.Home-Pane {
  width: 100%;
}

.Home-Pane-Main-Content {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*flex-wrap: wrap;*/
  text-align: left;
  font-size: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding-bottom: var(--default-padding-size);
}

.Home-Pane-Main-Content-Text-Section {
  display: flex;
  flex: 2 1;
  width: 75%;
}

.Big-Registration-Button {
  border: 4px solid black;
  border-radius: calc(var(--border-radius)*4);
  height: 60px;
  margin: 3px;
  padding: 12px;
  /*padding-bottom: 5%;*/
  cursor: pointer;
  font-size: 44px;
  background-color: var(--accent-color);
  color: black;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
}

.Big-Registration-Button:hover {
  color: var(--secondary-color);
}

.Announcements-Section {
  /*flex: 1;*/
  /*float: right;*/
  border: 2px solid black;
  padding: 0;
  margin-bottom: auto;
  /*width: max(40%, 400px);*/
}

.Announcements-Section-Title {
  background-color: var(--secondary-color);
  border-bottom: 2px solid black;
  text-align: center;
  font-weight: 600;
}

.Announcements-Section-Content {
  /*height: 200px; */
  overflow-x: hidden; 
  overflow-y: auto;
  padding: 6px; 
  text-align: left;
  background-color: var(--secondary-color);
}

.Announcements-Section-Content-Header {
  font-size: 120%;
  font-weight: 600;
  text-decoration: underline;
}

.Announcements-Section-Content-List {
  text-align: left;
}

.Question-Section {
  font-family: "Times New Roman", Times, serif;
}

.Question:not(:last-child) {
  margin-bottom: calc(var(--default-padding-size)*2);
}

.Question-Answerline {
  padding-left: var(--default-padding-size);
  border: 1px solid black;
  border-radius: var(--border-radius)
}

.Schedule-Pane,
.Stats-Pane {
  flex: 1;
  height: 100%;
}

.Schedule-Pane-Header,
.Stats-Pane-Header {
  align-items: center;
}

.Stats-Pane-Header > *,
.Teams-Pane-Header > *,
.Players-Pane-Header > *,
.Schedule-Pane-Header > * {
  margin: 4px 0;
}

.Stats-Pane-Header-Content {
  width: fit-content;
}

.Schedule-Pane-Filter-Box-Label,
.Stats-Pane-Filter-Box-Label,
.Teams-Pane-Filter-Box-Label,
.Players-Pane-Filter-Box-Label {
  padding-right: var(--default-padding-size);
}

.Away-Team-Cell {
}

.Home-Team-Cell {
}

.Stats-Pane-Header-Legend {
  text-align: left;
}

.Stats-Pane-Header-Legend-Title {
  padding-left: var(--default-padding-size);
}

.Stats-Pane-Header-Button {
  cursor: pointer;
}

.Stats-Pane-Header-Legend-Box {
  border: var(--border-thickness) solid var(--accent-color);
  border-radius: var(--border-radius);
  /*padding: calc(var(--default-padding-size)/2);*/
  display: flex;
  flex-wrap: wrap;
}

.Stats-Pane-Header-Legend-Item {
  display: inline;
  width: 170px;
}

.Schedule-Pane-Row-Container,
.Stats-Pane-Row-Container {
  width: fit-content;
  align-self: center;
}

table {
  border-bottom: 2px solid black;
}

.table {
  border-color: black;
}

tr:nth-child(even) {
  background: var(--azure-x-11-web-color)
}

.Table-Header-Cell {
  cursor: pointer;
  padding: 0 var(--default-padding-size);
  height: var(--block-size);
}

.Stats-Pane-Header-Inactive-Sort-Arrow,
.Teams-Pane-Header-Inactive-Sort-Arrow {
  color: lightgray;
}

.Stats-Pane-Header-Active-Sort-Arrow,
.Teams-Pane-Header-Active-Sort-Arrow {
  color: var(--default-text-color);
}

.Teams-Pane-Specific-Title {
  margin-left: calc(var(--default-padding-size)*2);
}

.Players-Pane-Specific-Back-Button,
.Teams-Pane-Specific-Back-Button {
  width: fit-content;
}

a.Players-Pane-Specific-Back-Button,
a.Teams-Pane-Specific-Back-Button {
  color: black;
  text-decoration: none;
}

.Schedule-Table-Title,
.Player-Table-Title,
.Teams-Table-Title,
.Teams-Pane-Table-Title {
  border-bottom: 1px solid var(--border-color);
}

.Scorer-Pane-Header-Section,
.Teams-Pane-Header-Section,
.Players-Pane-Header-Section {
  border: var(--border-thickness) solid var(--secondary-color);
  border-radius: var(--border-radius);
  padding: var(--default-padding-size);
  width: 100%;
}

.Scorer-Pane-Confirmation-Section {
  display: flex;
}

.Scorer-Pane-Confirmation-Section-Half {
  flex: 1;
}

.Scorer-Pane-File-Input,
.Scorer-Pane-MODAQ-Link,
.Scorer-Pane-Protest-Link {
  background-color: var(--secondary-header-color);
  color: white;
  padding: calc(var(--default-padding-size)/2);
  margin-bottom: var(--default-padding-size)!important;
  border-radius: var(--border-radius);
}

.Scorer-Pane-Team-Label {
  width: 300px;
}

.Conversion-Toggle-Button {
  border: var(--border-thickness) solid var(--border-color);
  padding: 0px;
  height: 40px;
}

.Conversion-Toggle-Button-Inner {
  padding: 6px 12px;
  text-decoration: none;
  color: var(--default-text-color);
}

a.Conversion-Toggle-Button-Inner:hover {
  color: var(--default-text-color);
}

.Table-Cell {
  padding: calc(var(--default-padding-size)/2);
  border: var(--border-thickness) solid var(--border-color);
  /*border-radius: var(--border-radius);*/
  width: fit-content;
  line-height: 1em;
}

.Table-Cell-N-Column {
  background-color: var(--header-background-color)!important;
  color: var(--secondary-color) !important;
}

.Table-Cell-Player-Column,
.Table-Cell-Team-Column {
  min-width: 50%;
  overflow: hidden;
}

.Table-Cell-G-Column,
.Table-Cell-15-Column,
.Table-Cell-10-Column,
.Table-Cell--5-Column {
  /*width: 40px;*/
  /*min-width: 40px;*/
}

.Table-Cell-PPG-Column,
.Table-Cell-PPB-Column,
.Table-Cell-TUH-Column {
  /*width: 50px;*/
  /*min-width: 50px;*/
}

.Table-Cell-Team-Column,
.Table-Cell-Players-Column {
  white-space: normal;
}

.Table-Cell-Home-Column,
.Table-Cell-Away-Column,
.Table-Cell-Opponent-Column {
  white-space: normal;
  max-width: 400px;
}

.Packet-Table-Cell-Week-Column {
  min-width: 150px;
}

.Toggle-Button {
  border: var(--border-thickness) solid var(--border-color);
}

.Active-Toggle-Button {
  background-color: var(--active-button-color);
}

.Vertical-Box {
  display: flex;
  flex-direction: column;
}

.Vertical-Box-Spaced > :not(:last-child) {
  padding-bottom: var(--default-padding-size);
}

.Horizontal-Box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Horizontal-Box-Spaced > :not(:last-child) {
  margin-right: var(--default-padding-size);
}

.FAQ-Section {
  word-wrap: break-word;
  width: 100%;
  text-align: left;
}

.FAQ-Section-Header {
  text-decoration: underline;
  padding-top: 0.25em;
  text-align: center
}

.FAQ-Question {
  /*padding-bottom: var(--default-padding-size);*/
}

.Collapsible {
  border: var(--border-color) solid var(--border-thickness);
  border-radius: calc(var(--border-radius)*2.5);
  margin-bottom: var(--default-padding-size);
}

.Collapsible__trigger {
  font-size: 1.2em;
  font-weight: 600;
  /*margin-bottom: 0%;*/
  /*border: var(--border-color) solid var(--border-thickness);*/
  border-radius: calc(var(--border-radius)*2);
  border-bottom: var(--primary-color) solid var(--border-thickness);
  cursor: pointer;
  padding: calc(var(--default-padding-size)/3);
  display: flex;
  white-space: normal;
  background-color: var(--secondary-color);
}

.Collapsible__contentInner {
  padding: var(--default-padding-size);
  overflow: auto;
}

.FAQ-Answer {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.Register-Pane-Input-Section {
  border: var(--border-color) solid var(--border-thickness);
  padding: var(--default-padding-size);
  width: fit-content;
  margin: 0 auto;
}

.Register-Pane-Info-Section {
  max-width: 700px;
}

.Register-Pane-Name-Label,
.Register-Pane-Email-Label {
  width: 6em;
}

.Register-Pane-Name-Input,
.Register-Pane-Email-Input {
  width: 12em;
}

.Register-Pane-Row-Section {
  margin: auto;
}

.Register-Pane-Row-Input {
  width: max(120px, 80%);
}

.Register-Pane-Add-Button {
  width: fit-content;
}

.Warning-Section {
  color: var(--primary-color);
}

.Register-Pane-Confirm-Section {
  align-items: center;
  width: fit-content;
  margin: auto;
}

.User-Pane-Row-Input {
  width: max(100px, 80%);
}

.User-Pane-Select-Label {
  min-width: calc(var(--block-size)*2);
}

.User-Pane-Select
{
  width: 100%;
}

.User-Pane-Select > * {
  margin-bottom: calc(var(--default-padding-size)/2);
}

.User-Pane-Before {
  color: var(--red-color);
}

.User-Pane-After {
  color: var(--green-color);
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: calc(2*var(--block-size)/3);
  height: calc(2*var(--block-size)/3);
  left: calc(var(--block-size)/3);
  top: calc(10px + var(--header-logo-size) + var(--block-size)/3);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: var(--primary-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: var(--burger-menu-padding-size)!important;
  width: 100%!important;
  padding-top: 8px;
  top: 0!important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--burger-menu-close-button-color);
}

/* General sidebar styles */
.bm-menu-wrap {
  background: var(--primary-color);
  padding-top: var(--burger-menu-padding-size);
  font-size: 1.15em;
  width: fit-content!important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: var(--burger-menu-morph-color);
}

/* Wrapper for item list */
.bm-item-list {
  color: var(--burger-menu-text-color);
  line-height: 1.5em;
}

.bm-item {
  padding: 0 40px;
}

.bm-item:hover {
  background-color: var(--hovered-button-color);
  color: var(--primary-color);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.Flex-Wrap {
  flex-wrap: wrap;
}

.Text-Wrap {
  white-space: normal;
}

.Left-Pad {
  padding-left: var(--default-padding-size);
}

.Right-Pad {
  padding-right: var(--default-padding-size);
}

.Top-Pad {
  padding-top: var(--default-padding-size);
}

.Margin-Bottom {
  margin-bottom: auto;
}

.Margin-Auto {
  margin: auto;
}

.Text-Left {
  text-align: left;
}

.Fit-Content {
  width: fit-content;
}

.Full-Width {
  width: 100%;
}

.Loading-Pane {
  margin: auto;
}

.Spacer {
  min-height: 50px;
}